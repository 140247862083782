import React from "react";
import Layout from "../components/Layout";
import Posts from "../components/Posts";
import { graphql } from "gatsby";
import SeO from "../components/SEO";

const CategoryTemplate = (props) => {
  // console.log(props)
  const posts = props.data.allMdx.nodes;
  const total = props.data.allMdx.totalCount;
  const category = props.pageContext.category;
  console.log(total);
  // console.log(category)
  return (
    <Layout>
      <SeO title={category} />
      <section className="pageContainer">
        {/* <Posts posts={posts} title={`category / ${category}`} /> */}
        <Posts posts={posts} title={`${total} ${category}`} />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query GetCategories($category: String) {
    allMdx(
      sort: { fields: frontmatter___Update, order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          category
          readTime
          slug
          Update(formatString: "MMM,Do,YYYY")
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        excerpt
      }
      totalCount
    }
  }
`;

export default CategoryTemplate;
